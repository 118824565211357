import { createSelector } from 'reselect';
import { IGalleryInjectedProps, IStore } from '../../interfaces';
import { OrderCategoriesEnum, MatchOrderCategoriesEnum, EnumSection } from '../../interfaces/clientInterfaces';
import { matchCompetitionDefault, initialConfederations } from '../../reducers';
import { Countries } from '../../utils/countries';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	): IGalleryInjectedProps => {
		if (state && Array.isArray(state.scarves.currentGroupScarves)) {
			let currentGroupScarves = state.scarves.currentGroupScarves;
			const start = state.orderAndPaging.allPagingInfo.currentPage * state.orderAndPaging.allPagingInfo.teamsPerPage;
			const end = start + state.orderAndPaging.allPagingInfo.teamsPerPage;
			currentGroupScarves = currentGroupScarves.slice(
				start,
				end >= currentGroupScarves.length ? currentGroupScarves.length : end
			);
			const regionsSet = new Set<string>();
			if (Countries.isCountrySelectableByRegion(state.ui.currentCountry)) {
				const teams = state.teams.teamsByCountryCode[state.ui.currentCountry];
				if (teams) {
					for (const team of teams) {
						regionsSet.add(team.city?.region)
					}
				}
			}

			return {
				countryCodes: state.general.countryCodes,
				currentGroupScarves,
				orderBy: state.orderAndPaging.orderBy,
				allPagingInfo: state.orderAndPaging.allPagingInfo,
				isMobileViewActive: state && state.ui.viewPort ? state.ui.viewPort.width <= 834 && state.ui.viewPort.height <= 1112 : false,
				section: state.ui.section,
				trophyTooltip: state.ui.trophyTooltip,
				isReducedView: state.ui.isReducedView,
				currentCountry: state.ui.currentCountry,
				areTeamHonoursVisible: state.ui.areTeamHonoursVisible,
				detailTrophyTrophy: state.ui.detailTrophyTooltip,
				regions: Array.from(regionsSet).map(reg => ({ text: reg, value: reg })).sort((a,b) => a.text > b.text ? 1 : -1),
				competitionsWithEvolution: state.competitions.competitionsWithEvolution
			};
		}
		return {
			countryCodes: [],
			currentGroupScarves: [],
			regions: [],
			orderBy: {
				category: OrderCategoriesEnum.alphabetically,
				ascending: true,
				confederations: initialConfederations,
				matchCompetition: matchCompetitionDefault,
				matchAscending: false,
				matchCategory: [MatchOrderCategoriesEnum.competition],
				countryCodes: {},
				orderByRegion: ''
			},
			allPagingInfo: state.orderAndPaging.allPagingInfo,
			isMobileViewActive: state ? state.ui.isMobileViewActive : false,
			section: EnumSection.home,
			trophyTooltip: null,
			isReducedView: false,
			currentCountry: state.ui.currentCountry,
			areTeamHonoursVisible: state.ui.areTeamHonoursVisible,
			detailTrophyTrophy: state.ui.detailTrophyTooltip,
			competitionsWithEvolution: state.competitions.competitionsWithEvolution
		};
	}
);
