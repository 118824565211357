import { IDBCompetition } from '../interfaces/dbInterfaces';
import { ICompetitionEvolution, ISelectedEvolutions, ITeam, ITeamTimeHonours, ITitle } from '../interfaces/clientInterfaces';
import { INumberDictionary, IStringDictionary } from '../interfaces';
import { Countries } from './countries';

export class Competitions {

	public static getSeasonsInTopCategoryString = (
		team: ITeam
	): string => {
		let seasonsTop = '';
		if (team.seasonsTopTotal) {
			seasonsTop += `${team.seasonsTop || '0'}/${team.seasonsTopTotal}`;
			if (team.countryCode2) {
				const secondaryCountrySeasons = team.seasonsTop2
					? `${team.seasonsTop2}/${team.seasonsTopTotal2}`
					: `0/${team.seasonsTopTotal2}`;

				if (
					team.countryCode === 'WL' ||
					(team.countryCode === 'AD' && team.countryCode2 === 'ES')
				) {
					seasonsTop = `${secondaryCountrySeasons} ${team.countryCode === 'WL' ? '(England)' : '(Spain)'}`;
				} else {
					let country2Name = '';
					if (team.countryCode2) {
						switch (team.countryCode2) {
							case 'ZK':
								country2Name = 'Czechoslovakia';
								break;
							case 'UR':
								country2Name = 'Soviet Union';
								break;
							case 'YU':
								country2Name = 'Yugoslavia';
								break;
							case 'DR':
								country2Name = 'East Germany';
								break;
							case 'ES':
								country2Name = 'Spain';
								break;
							case 'NI':
								country2Name = 'Northern Ireland';
								break;
							case 'IE':
								country2Name = 'Republic of Ireland';
								break;
							case 'IT':
								country2Name = 'Italy';
								break;
							case 'HU':
								country2Name = 'Hungary';
								break;
							case 'PL':
								country2Name = 'Poland';
								break;
							case 'HE':
								country2Name = 'Greece';
								break;
							case 'CY':
								country2Name = 'Cyprus';
								break;
							default:
								country2Name = '';
								break;
						}
					}
					seasonsTop += ` (${secondaryCountrySeasons} ${country2Name})`;
				}
			}
		} else if (team.seasonsTopTotal2) {
			seasonsTop += `${team.seasonsTop2 || 0}/${team.seasonsTopTotal2} ${team.countryCode === 'LI' ? '(Switzerland)' : ''}`;
		}
		return seasonsTop;
	}

	public static teamTitlesSorter = (
		titles: ITitle[],
		competitionsDictionary: INumberDictionary<IDBCompetition>
	): ITitle[] => {
		return titles.sort((a, b) => {
			const competitionA = competitionsDictionary[a.competitionId];
			const competitionB = competitionsDictionary[b.competitionId];
			if (competitionA && competitionB) {
				if (competitionA.isContinental) {
					if (competitionB.isInternational) {
						return -1;
					} else if (competitionB.isContinental) {
						if (competitionA.weight > competitionB.weight) {
							return -1;
						} else {
							return 1;
						}
					} else {
						return 1;
					}

				} else if (competitionA.isInternational) {
					if (!competitionB.isInternational) {
						return 1;
					} else {
						if (competitionA.weight > competitionB.weight) {
							return -1;
						} else {
							return 1;
						}
					}
				} else {
					if (competitionB.isContinental || competitionB.isInternational) {
						return -1;
					} else {
						if (competitionA.weight > competitionB.weight) {
							return -1;
						} else {
							return 1;
						}
					}
				}
			}
			return -1;
		});
	}

	public static getSeasonsTopPercentage = (
		countryCode: string,
		competition: IDBCompetition | null,
		competition2: IDBCompetition | null,
		countryCode2?: string,
		seasonsTop?: number,
		seasonsTop2?: number
	): number => {
		if (competition?.totalEditions !== undefined) {
			if (countryCode2 && competition2?.totalEditions !== undefined) {
				if (countryCode === 'DE' || countryCode === 'WL' || countryCode === 'NI') {
					const totalSeasonsTop = (seasonsTop !== undefined ? seasonsTop : 0) + (seasonsTop2 !== undefined ? seasonsTop2 : 0);
					const totalTotalEditions = competition?.totalEditions;
					return totalSeasonsTop / totalTotalEditions;
				} else {
					const totalSeasonsTop = (seasonsTop !== undefined ? seasonsTop : 0) + (seasonsTop2 !== undefined ? seasonsTop2 : 0);
					const totalTotalEditions = competition2?.totalEditions + competition?.totalEditions;
					return totalSeasonsTop / totalTotalEditions;
				}

			} else {
				if (seasonsTop !== undefined) {
					return seasonsTop / competition.totalEditions;
				}
				return 0;
			}
		}
		return 0;
	}

	private static _getRegionFlagCode = (
		team: ITeam,
		year: number,
		competition?: IDBCompetition,
	): string => {
		if (competition?.isContinental) {
			return Countries.getFlagOfCountry(
				year,
				competition.region_code || '',
				team.countryCode,
				team.countryCode2,
				!(competition?.isContinental || competition?.isInternational)
			);
		} else {
			if (team.countryCode !== competition?.region_code || team.countryCode2 === 'MC') {
				return team.countryCode2 === 'MC'
					? 'MC'
					: Countries.getFlagOfCountry(
						year,
						competition?.region_code || '',
						team.countryCode,
						team.countryCode2,
						!(competition?.isContinental || competition?.isInternational)
					);
			}
		}
		return '';
	}

	public static getCompetitionEvolutionOfRange = (
		selectedStartIndex: number,
		selectedEndIndex: number,
		evolution: ICompetitionEvolution,
		teamsById: INumberDictionary<ITeam>,
		competition?: IDBCompetition
	): ISelectedEvolutions => {
		const selectedTimeStatus: ITeamTimeHonours[] = [];
		const selectedTimeStatusByTeamName: IStringDictionary<ITeamTimeHonours> = {};
		let selectedStartYear = evolution.startYear + selectedStartIndex;
		let selectedEndYear = evolution.startYear + selectedEndIndex;
		let lastChampionCode = '';
		let lastRunnerUpCode = '';
		let lastChampionTooltip = 'Missing scarf';
		let lastChampionId = -1;
		let lastRunnerUpTooltip = 'Missing scarf';
		let lastRunnerUpId = -1;
		let selectedStartYearText = '';
		let selectedEndYearText = '';
		let lastChampionRegionCode = '';
		let lastRunnerUpRegionCode = '';
		if (selectedStartIndex >= 0 && selectedEndIndex >= selectedStartIndex && selectedEndIndex <= evolution.editions.length - 1) {
			const endEdition = evolution.editions[selectedEndIndex];
			const lastChampionsTeam = teamsById[endEdition.lastChampionId];
			if (lastChampionsTeam) {
				const lastChampionsYearShield = [...lastChampionsTeam.shields].reverse().find((shield: string) => Number.parseInt(shield) >= endEdition.endYear);
				lastChampionCode = `${lastChampionsTeam.countryCode}_${lastChampionsTeam.teamCode}${lastChampionsYearShield ? `_${lastChampionsYearShield}` : ''}`;
				lastChampionTooltip = `Champion: ${lastChampionsTeam.name}`;
				lastChampionRegionCode = Competitions._getRegionFlagCode(lastChampionsTeam, selectedEndYear, competition);
				lastChampionId = lastChampionsTeam.teamId;
			}
			const lastRunnerUpTeam = teamsById[endEdition.lastRunnerUpId];
			if (lastRunnerUpTeam) {
				const lastRunnerUpYearShield = [...lastRunnerUpTeam.shields].reverse().find((shield: string) => Number.parseInt(shield) >= endEdition.endYear);
				lastRunnerUpCode = `${lastRunnerUpTeam.countryCode}_${lastRunnerUpTeam.teamCode}${lastRunnerUpYearShield ? `_${lastRunnerUpYearShield}` : ''}`;
				lastRunnerUpTooltip = `Runner up: ${lastRunnerUpTeam.name}`;
				lastRunnerUpRegionCode = Competitions._getRegionFlagCode(lastRunnerUpTeam, selectedEndYear, competition);
				lastRunnerUpId = lastRunnerUpTeam.teamId;
			}
			const startEdition = selectedStartIndex > 0 ? evolution.editions[selectedStartIndex - 1] : {
				...evolution.editions[0],
				champions: {},
				runnerUps: {}
			};
			const startEditionForTitle = evolution.editions[selectedStartIndex];
			selectedStartYear = startEditionForTitle.endYear;
			selectedStartYearText = startEditionForTitle.yearText;
			selectedEndYear = endEdition.endYear;
			selectedEndYearText = endEdition.yearText;
			const endEditionChampionKeys: string[] = Object.keys(endEdition.champions);
			for (const endEditionChampionKey of endEditionChampionKeys) {
				const championKeyAsNumber = parseInt(endEditionChampionKey);
				if (!Number.isNaN(championKeyAsNumber)) {
					const team = teamsById[championKeyAsNumber];
					const endEditionChampionTimes = endEdition.champions[championKeyAsNumber]?.length ?? 0;
					const startEditionChampionTimes = startEdition.champions[championKeyAsNumber]?.length ?? 0;
					if (typeof team !== 'undefined') {
						if (typeof endEditionChampionTimes !== 'undefined') {
							const teamShield = [...team.shields].reverse().find((shield: string) => Number.parseInt(shield) >= selectedEndYear);
							selectedTimeStatusByTeamName[team.name] = {
								shieldCode: `${team.countryCode}_${team.teamCode}${teamShield ? `_${teamShield}` : ''}`,
								scarfCode: `${team.countryCode}_${team.teamCode}`,
								teamId: team.teamId,
								teamName: team.name,
								timesChampion: endEditionChampionTimes - (typeof startEditionChampionTimes !== 'undefined' ? startEditionChampionTimes : 0),
								timesRunnerUp: 0,
								regionCode: Competitions._getRegionFlagCode(team, selectedEndYear, competition)
							};
						}
					}
				}
			}
			const endEditionRunnerUpKeys: string[] = Object.keys(endEdition.runnerUps);
			for (const endEditionRunnerUpKey of endEditionRunnerUpKeys) {
				const runnerUpKeyAsNumber = parseInt(endEditionRunnerUpKey);
				if (!Number.isNaN(runnerUpKeyAsNumber)) {
					const team = teamsById[runnerUpKeyAsNumber];
					if (typeof team !== 'undefined') {
						const endEditionRunnerUpTimes = endEdition.runnerUps[runnerUpKeyAsNumber]?.length ?? 0;
						const startEditionRunnerUpTimes = startEdition.runnerUps[runnerUpKeyAsNumber]?.length ?? 0;
						if (typeof endEditionRunnerUpTimes !== 'undefined') {
							if (selectedTimeStatusByTeamName[team.name]) {
								selectedTimeStatusByTeamName[team.name] = {
									...selectedTimeStatusByTeamName[team.name] as ITeamTimeHonours,
									timesRunnerUp: endEditionRunnerUpTimes - (typeof startEditionRunnerUpTimes !== 'undefined' ? startEditionRunnerUpTimes : 0)
								};
							} else {
								const teamShield = [...team.shields].reverse().find((shield: string) => Number.parseInt(shield) >= selectedEndYear);
								selectedTimeStatusByTeamName[team.name] = {
									shieldCode: `${team.countryCode}_${team.teamCode}${teamShield ? `_${teamShield}` : ''}`,
									scarfCode: `${team.countryCode}_${team.teamCode}`,
									teamId: team.teamId,
									teamName: team.name,
									timesChampion: 0,
									timesRunnerUp: endEditionRunnerUpTimes - (typeof startEditionRunnerUpTimes !== 'undefined' ? startEditionRunnerUpTimes : 0),
									regionCode: Competitions._getRegionFlagCode(team, selectedEndYear, competition)
								};
							}
						}

					}
				}
			}
		}
		const filteredTimeStatus: ITeamTimeHonours[] = [];
		let maxTitlesInCurrentStatus = 0;
		for (const statusOfTeam of Object.values(selectedTimeStatusByTeamName)) {
			if (statusOfTeam) {
				selectedTimeStatus.push(statusOfTeam);
				if (statusOfTeam.timesChampion > maxTitlesInCurrentStatus) {
					maxTitlesInCurrentStatus = statusOfTeam.timesChampion;
				}
				if (statusOfTeam.timesChampion > 0 || statusOfTeam.timesRunnerUp > 0) {
					filteredTimeStatus.push(statusOfTeam);
				}
			}
		}
		const orderedSelectedTimeStatus = filteredTimeStatus.sort((a, b) => {
			if (a.timesChampion > b.timesChampion) {
				return -1;
			} else if (a.timesChampion < b.timesChampion) {
				return 1;
			} else if (a.timesChampion === b.timesChampion) {
				if (a.timesRunnerUp > b.timesRunnerUp) {
					return -1;
				} else if (a.timesRunnerUp < b.timesRunnerUp) {
					return 1;
				}
			}
			return -1;
		});
		let competitionTrophy = '';
		if (competition) {
			competitionTrophy = `${competition.region_code}_${competition.competition_code}`;
			if (competition.trophies) {
				for (const trophy of competition.trophies.split(';')) {
					const trophyYear = parseInt(trophy);
					if (selectedEndYear > trophyYear) {
						continue;
					}
					competitionTrophy = `${competitionTrophy}_${trophy}`;
					break;
				}
			}
		}
		return {
			evolution,
			selectedStartYear,
			selectedEndYear,
			competitionId: competition?.competition_id || -1,
			competitionName: competition?.name || '',
			competitionDescription: competition?.description || '',
			competitionTrophy,
			selectedStartIndex,
			selectedEndIndex,
			maxTitlesInCurrentStatus,
			selectedTimeStatus: orderedSelectedTimeStatus,
			lastChampionCode,
			lastRunnerUpCode,
			lastChampionTooltip,
			lastChampionId,
			lastRunnerUpId,
			lastRunnerUpTooltip,
			selectedStartYearText,
			selectedEndYearText,
			lastChampionRegionCode,
			lastRunnerUpRegionCode
		};
	}


	public static getCompetitionRegionImagePath = (
		regionCode: string,
	): string => {
		switch (regionCode) {
			case 'OC':
				return './../../img/shield/OC.png';
			case 'EU':
				return './../../img/shield/EU_EC.png';
			case 'AF':
				return './../../img/shield/AF_CAF.png';
			case 'AS':
				return './../../img/shield/AS_AFC.png';
			case 'NA':
				return './../../img/shield/NA_CON.png';
			case 'SA':
				return './../../img/shield/SA_CON.png';
			case 'FF': {
				return './../../img/shield/FF_WC.png';
			}
			default:
				return `./../../img/flag/${regionCode}.png`;
		}
	}

	public static _confederationComparer = (
		dbCompetitionA: IDBCompetition,
		dbCompetitionB: IDBCompetition
	): number => {
		switch (dbCompetitionA.region_code) {
			case 'EU': {
				return dbCompetitionA.weight > dbCompetitionB.weight ? 1 : -1;
			}
			case 'SA': {
				if (dbCompetitionB.region_code === 'EU') {
					return -1;
				} else if (dbCompetitionB.region_code === 'SA') {
					return dbCompetitionA.weight > dbCompetitionB.weight ? 1 : -1;
				}
				return 1;
			}
			case 'AF': {
				if (dbCompetitionB.region_code === 'EU' || dbCompetitionB.region_code === 'SA') {
					return -1;
				} else if (dbCompetitionB.region_code === 'AF') {
					return dbCompetitionA.weight > dbCompetitionB.weight ? 1 : -1;
				}
				return 1;
			}
			case 'NA': {
				if (dbCompetitionB.region_code === 'EU' || dbCompetitionB.region_code === 'SA' || dbCompetitionB.region_code === 'AF') {
					return -1;
				} else if (dbCompetitionB.region_code === 'NA') {
					return dbCompetitionA.weight > dbCompetitionB.weight ? 1 : -1;
				}
				return 1;
			}
			case 'AS': {
				if (dbCompetitionB.region_code === 'EU' || dbCompetitionB.region_code === 'SA' || dbCompetitionB.region_code === 'AF' || dbCompetitionB.region_code === 'NA') {
					return -1;
				} else if (dbCompetitionB.region_code === 'AS') {
					return dbCompetitionA.weight > dbCompetitionB.weight ? 1 : -1;
				}
				return 1;
			}
			default:
				return 1;
		}
	}

	public static orderDbCompetitions = (
		dbCompetitions: IDBCompetition[]
	): IDBCompetition[] => {
		return dbCompetitions.sort((a, b) => {
			if (a.isContinental) {
				if (b.isContinental) {
					return a.weight >= b.weight ? -1 : 1;
				} else {
					return -1;
				}
			} else {
				if (b.isContinental) {
					return 1;
				} else if (a.region_code > b.region_code) {
					return 1;
				} else if (a.region_code < b.region_code) {
					return -1;
				} else {
					return a.weight >= b.weight ? -1 : 1;
				}
			}
		});
	}

}
