import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import rootSelector from '../selectors/gallery';
import { IGalleryCombinedProps, IGalleryState } from '../../interfaces';
import { IScarfInfo, EnumSection, ITeam, OrderCategoriesEnum } from '../../interfaces/clientInterfaces';
import * as actions from '../../actions';
import DropDown from './DropDown';
import { ALL_COUNTRIES_NAME, ITEMS_PER_PAGE_ITEMS, ITEMS_PER_PAGE, PAGE_KEY, SHOW_MAP_KEY, SET_SIMPLE_VIEW, SET_EXTENDED_VIEW, SELECT_BY_COUNTRIES, SELECTED_COUNTRIES, HONOURS_ORDER_KEY, SORT_TITLE_KEY, NUMBER_HONOURS_ORDER_KEY, YEAR_ORDER_KEY, CATEGORY_ORDER_KEY } from '../constants';
import TeamInfoRenderer from './TeamInfoRenderer';
import { Helmet } from 'react-helmet';
import { StyledAnimatedOnHover } from './SideTree';
import HighlightsPopUp from './HighlightsPopUp';
import TrophyTooltip from './TrophyTooltip';
import { Link } from 'react-router-dom';
import { Localizer } from '../../utils/localizer';
import DetailTrophyTooltip from './DetailTrophyTooltip';
import { IDropDownItem } from './DropDown/interfaces';
import { Countries } from '../../utils/countries';

//#region styled Components
const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;
const TitleContainer = styled.div`
	margin-left: 5px;
	padding: 5px;
	text-shadow: -1px 0 rgb(248,149,4), 0 1px rgb(248,149,4), 1px 0 rgb(248,149,4), 0 -1px rgb(248,149,4);
`;
const TitleContainerWithoutPadding = styled.div`
	padding: 5px 0 5px 0;
	margin: 0;
	text-shadow: -1px 0 rgb(248,149,4), 0 1px rgb(248,149,4), 1px 0 rgb(248,149,4), 0 -1px rgb(248,149,4);

`;
const OrderContainer = styled.div`
	padding: 10px 27px 10px 10px;
	display: flex;
	margin: 0 auto 0 auto;
	display: flex;
	flex-direction: column;
`;

const SortContainer = styled.div`
	display: flex;
	margin-right: 10px
`;
const PageNumberInput = styled.input`
	color: white;
	font-size: 16px;
	line-height: 16px;
	width: 20px;
	text-align: center;
	background-color: transparent;
	border: none;
	padding: 5px 0 5px 0;
	text-shadow: -1px 0 rgb(248,149,4), 0 1px rgb(248,149,4), 1px 0 rgb(248,149,4), 0 -1px rgb(248,149,4);
	:focus {
		outline: none;
	}
`;

const DropdownContainerPaging = styled.div`
	width: 52px;
	float: right;
	margin-left: 10px;
`;

const GalleryContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	width: 100vw;
	background-repeat: no-repeat;
	background-position: center center;
	transition: background-image 0.5s;
`;

const IconPagingContainer = styled(StyledAnimatedOnHover)`
	cursor: pointer;
	margin: auto;
	padding: 0 3px 0 3px;
	font-size: 12px;
	line-height: 12px;
	text-shadow: -1px 0 rgb(248,149,4), 0 1px rgb(248,149,4), 1px 0 rgb(248,149,4), 0 -1px rgb(248,149,4);
	:hover {
		color: #F89504;
	}
`;

const MapIconContainer = styled(StyledAnimatedOnHover)`
	display: flex;
	margin: 0 5px 0 5px;
	:hover {
		color: #F89504;
		i {
			color: #F89504;
		}
	}
	a {
		color: transparent;
	}
	i {
		font-size: 15px;
		line-height: 30px;
		cursor: pointer;
		color: white;
		text-shadow: -1px 0 rgb(248,149,4), 0 1px rgb(248,149,4), 1px 0 rgb(248,149,4), 0 -1px rgb(248,149,4);
	}
`;

const SimpleViewIconContainer = styled(MapIconContainer)`
	margin-left: 8px;
`;

const ImageLinkContainer = styled.img`
	max-width: 23px;
	margin-right: 5px;
`;

const SelectCountryFlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	width: 100vw;
	background-repeat: no-repeat;
	background-position: center center;
	transition: background-image 0.5s;
`;

interface IStyledCountryRow {
	isSelected: boolean;
}
const StyledCountryRow = styled(StyledAnimatedOnHover)<IStyledCountryRow>`
	font-family: Europa-Big;
	font-size: 16px;
	text-align: center;
	margin: auto;
	cursor: pointer;
	text-align: left;
	padding: 1.5px;
	${props => props.isSelected
		? 'background-color: #F89504;'
		: ''}
	a {
		text-decoration: none;
		color: white;
	}
	:hover {
		background-color: #F89504;
		a {
			color: black;
		}
	}
`;

const StyledFilterByCountryButton = styled(StyledAnimatedOnHover)`
	margin-bottom: 25px;
	font-family: Europa-Big;
	font-size: 16px;
	text-align: center;
	margin: 10px auto;
	cursor: pointer;
	padding: 10px;
	background-color: #F89504;
	a {
		text-decoration: none;
		color: black;
	}
	:hover {
		background-color: #F89504;
		a {
			color: black;
		}
	}
`;

const CountryListContainer = styled.div`
	margin: auto;
	padding: 20px;
	overflow-y: auto;
	position: relative;
	height: 100%;
	background-color:rgba(0, 0, 0, 0.3);
`;

const StyledDropDown = styled(DropDown)`
	width: 250px;
	margin: auto;
`;

const DropdownItem = styled.div`
	height: 30px;
	display: flex;
	flex-direciton: row;
	cursor: pointer;
`;

const DropdownFlagContainer = styled.div`
	width: 40px;
	display: block;
	margin: auto;
`;

const DropdownRegionFlag = styled.img`
	max-height: 17px;
	margin: auto;
	display: block;
	border: 1px solid grey;
`;

const DropdownName = styled.div`
	line-height: 30px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const FilterContainerRow = styled.div`
	display: flex;
	flex-direction: row;
	margin: auto;
`;

const FilterContainerTopRow = styled.div`
	display: flex;
	flex-direction: row;
	margin: auto auto 5px auto;
`;

const FilterContainerIconsRow = styled.div`
	margin-left: 5px;
	display: flex;
	flex-direction: row;
`;

interface IOrderByIconContainer {
	isSelected: boolean;
}
const OrderByIconContainer = styled(StyledAnimatedOnHover)<IOrderByIconContainer>`
	display: flex;
	margin: auto 5px 0 5px;
	:hover {
		color: #F89504;
		i {
			color: #F89504;
		}
	}
	a {
		color: transparent;
	}
	i {
		font-size: 15px;
		line-height: 30px;
		cursor: pointer;
		color: ${props => props.isSelected ? '#F89504' : 'white'};
		text-shadow: -1px 0 rgb(248,149,4), 0 1px rgb(248,149,4), 1px 0 rgb(248,149,4), 0 -1px rgb(248,149,4);
	}
`;

//#endregion

class Gallery extends PureComponent<IGalleryCombinedProps, IGalleryState> {

	private _containerRef: HTMLDivElement | null = null;
	private _containerGetRef = (div: HTMLDivElement | null) => this._containerRef = div;

	private _bckContainer: HTMLDivElement | null = null;
	private _bckContainerGetRef = (div: HTMLDivElement | null) => this._bckContainer = div;

	constructor(props: IGalleryCombinedProps) {
		super(props);
		this.state = {
			renderSelectByCountry: props.code === SELECT_BY_COUNTRIES
		};
	}

	public componentDidMount() {
		this.props.dispatch(actions.setInfoOfCountry(this.props.code));
		this.props.dispatch(actions.setSectionName(EnumSection.gallery));
		this._changeBckImage();
	}

	public componentDidUpdate(prevProps: IGalleryCombinedProps, prevState: IGalleryState) {
		if (prevProps.code !== this.props.code) {
			this.setState({
				renderSelectByCountry: this.props.code === SELECT_BY_COUNTRIES
			});
			this._changeBckImage();
			if (this._containerRef) {
				this._containerRef.scrollTop = 0;
			}
			this.props.dispatch(actions.setInfoOfCountry(this.props.code));
		}
		if (this.props.countryCodes.length !== prevProps.countryCodes.length) {
			this._changeBckImage();

		}
		if (this.props.allPagingInfo.currentPage !== prevProps.allPagingInfo.currentPage) {
			if (this._containerRef) {
				this._containerRef.scrollTop = 0;
			}
		}
		if (this.state.renderSelectByCountry !== prevState.renderSelectByCountry) {
			this._changeBckImage();
		}
	}

	private _changeBckImage = () => {
		const backImage = this._getBckImage(this.props.code || '');
		var image = new Image();
		image.src = `./../../img/background/${backImage}.jpg`;
		image.onload = this._onBckImageOnLoad(backImage);
	}

	private _onBckImageOnLoad = (bckImage: string): any => {
		if (this._bckContainer) {
			this._bckContainer.style.backgroundImage = `url('../img/background/${bckImage}.jpg')`;
		}
	}

	private _getBckImage = (code: string) => {
		let bckImageCode = '1';
		if (code) {
			if (
				code === ALL_COUNTRIES_NAME ||
				code === SELECT_BY_COUNTRIES ||
				code === SELECTED_COUNTRIES ||
				code === 'EU' ||
				code === 'AS' ||
				code === 'AF' ||
				code === 'SA' ||
				code === 'NA' ||
				code === 'OC'
			) {
				const countryCodeIndex = Math.floor(Math.random() * (this.props.countryCodes.length - 1 - 0 + 1) + 0);
				if (countryCodeIndex < this.props.countryCodes.length) {
					bckImageCode = this.props.countryCodes[countryCodeIndex];
				} else {
					bckImageCode = this.props.countryCodes[this.props.countryCodes.length -1];
				}
			} else {
				bckImageCode = `${this.props.code}`;
			}
		}
		return bckImageCode;
	}

	private _setHighlightsPopUp = (teamId: number) => {
		this.props.dispatch(actions.setHighlightPopUp(teamId));
	}

	private _onTrophyClickCallback = (x: number, y: number, title: string, editions: string, description: string | undefined, competitionId: number) => {
		this.props.dispatch(actions.setTrophyTooltip({ x, y, title, editions, description, hasDetail: !!this.props.competitionsWithEvolution[competitionId], competitionName: this.props.competitionsWithEvolution[competitionId]?.name ?? '' }));
	}

	private _onSetHonoursVisibility = () => {
		this.props.dispatch(actions.setHonoursVisibility());
	}

	private _onSetDetailTrophyTooltip = (team: ITeam) => {
		this.props.dispatch(actions.onSetTitleDetail(team));
	}

	private _renderScarvesOfTeam = (scarfInfo: IScarfInfo) => {
		return (
			<TeamInfoRenderer
				key={`${scarfInfo.team.name} info key`}
				scarfInfo={scarfInfo}
				isReducedView={this.props.isReducedView}
				isMobileViewActive={this.props.isMobileViewActive}
				section={this.props.section}
				setHighlightsPopUp= {this._setHighlightsPopUp}
				onTrophyClickCallback={this._onTrophyClickCallback}
				currentCountry={this.props.currentCountry}
				onSetHonoursVisibility={this._onSetHonoursVisibility}
				areTeamHonoursVisible={this.props.areTeamHonoursVisible}
				onSetDetailTrophyTooltip={this._onSetDetailTrophyTooltip}
			/>
		);
	}

	private _onChangePage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value) {
			const number = parseInt(e.target.value);
			if (!Number.isNaN(number) && number >= 1 && number <= this.props.allPagingInfo.totalPages) {
				this.props.dispatch(actions.changeCurrentPage(number - 1));
			}
		}
	}

	private _changeItemsPerPage = (value: string | null) => {
		if (value) {
			const number = parseInt(value);
			if (!Number.isNaN(number)) {
				this.props.dispatch(actions.changeItemsPerPage(number));
			}
		}
	}

	private _onChangePageRight = () => {
		this.props.dispatch(actions.changeCurrentPage(this.props.allPagingInfo.currentPage + 1));
	}

	private _onChangePageLeft = () => {
		this.props.dispatch(actions.changeCurrentPage(this.props.allPagingInfo.currentPage - 1));
	}

	private _renderPagingControl = () => {
		if (this.props.allPagingInfo.totalPages <= 1 && this.props.allPagingInfo.teamsPerPage !== 50) {
			return null;
		}
		return (
			<>
				{!this.props.isMobileViewActive ? <TitleContainerWithoutPadding>{PAGE_KEY}</TitleContainerWithoutPadding> : null}
				{this.props.allPagingInfo.currentPage > 0 ? <IconPagingContainer><i className='fa fa-arrow-left' onClick={this._onChangePageLeft}/></IconPagingContainer> : null}
				<PageNumberInput
					max={this.props.allPagingInfo.totalPages}
					min={1}
					value={this.props.allPagingInfo.currentPage + 1}
					onChange={this._onChangePage}
				/>
				<TitleContainerWithoutPadding>{`/ ${this.props.allPagingInfo.totalPages}`}</TitleContainerWithoutPadding>
				{this.props.allPagingInfo.currentPage + 1 < this.props.allPagingInfo.totalPages ? <IconPagingContainer><i className='fa fa-arrow-right' onClick={this._onChangePageRight}/></IconPagingContainer> : null}
				{!this.props.isMobileViewActive ? <TitleContainer>{ITEMS_PER_PAGE}</TitleContainer> : null}
				<DropdownContainerPaging>
					<DropDown
						clearable={false}
						options={ITEMS_PER_PAGE_ITEMS}
						value={this.props.allPagingInfo.teamsPerPage.toString()}
						onChange={this._changeItemsPerPage}
						searchable={false}
					/>
				</DropdownContainerPaging>
			</>
		);
	}

	private _renderMapIcon = () => {
		if (this.props.code === ALL_COUNTRIES_NAME) {
			return null;
		}
		return (
			<MapIconContainer>
				<Link to={`/map?center=${this.props.name}`}>
					<i className='fa fa-map-marker' title={SHOW_MAP_KEY}/>
				</Link>
			</MapIconContainer>
		);
	}

	private _renderSimpleView = () => {
		return (
			<SimpleViewIconContainer onClick={() => this.props.dispatch(actions.setReducedView(!this.props.isReducedView))}>
				{!this.props.isReducedView ? <i className='fa fa-list-ol' title={SET_SIMPLE_VIEW}/> : <i className='fa fa-trophy' title={SET_EXTENDED_VIEW}/>}
			</SimpleViewIconContainer>
		);
	}

	private _onFilterByRegion = (value: string | null) => {
		this.props.dispatch(actions.filterByRegion(value));
	};
	
	private _renderDropDownValue = (item: IDropDownItem, isSelected: boolean) => {
		return (
			<DropdownItem title={item.text}>
				<DropdownFlagContainer>
					<DropdownRegionFlag
						src={`./../../img/flag/${item.text}.png`}
					/>
				</DropdownFlagContainer>
				<DropdownName>{item.text}</DropdownName>
			</DropdownItem>
		);
	}

	private _renderRegionsDropdown = () => {
		if (this.props.section !== EnumSection.gallery || !Countries.isCountrySelectableByRegion(this.props.currentCountry)) {
			return null
		}
		return (
			<StyledDropDown
				value={this.props.orderBy.orderByRegion || ''}
				options={this.props.regions}
				clearable={true}
				searchable={false}
				placeholder={'Filter by region'}
				onChange={this._onFilterByRegion}
				renderItem={(item: IDropDownItem, isSelected: boolean) => this._renderDropDownValue(item, isSelected)}
				renderValue={(item: IDropDownItem) => this._renderDropDownValue(item, false)}
			/>
		);
	}

	private _renderMapAndSimplifiedViewIcons = () => {
		return (
			<FilterContainerIconsRow>
				{this._renderMapIcon()}
				{this._renderSimpleView()}			
			</FilterContainerIconsRow>
		);
	}

	private _onOrderByHonours = () => {
		this.props.dispatch(actions.changeOrderCategory(OrderCategoriesEnum.honours));
	};

	private _onOrderByNumberOfTitles = () => {
		this.props.dispatch(actions.changeOrderCategory(OrderCategoriesEnum.numberTitles));
	};

	private _onOrderByFoundation = () => {
		this.props.dispatch(actions.changeOrderCategory(OrderCategoriesEnum.foundation));
	};

	
	private _onOrderByCategory = () => {
		this.props.dispatch(actions.changeOrderCategory(OrderCategoriesEnum.category));
	};

	private _renderOrderContainer = () => {
		return (
			<OrderContainer>
				<FilterContainerTopRow>
					<SortContainer>
						{this._renderPagingControl()}
					</SortContainer>
					<OrderByIconContainer isSelected={this.props.orderBy.category === OrderCategoriesEnum.honours} onClick={this._onOrderByHonours}>
						<i className='fa fa-medal' title={`${SORT_TITLE_KEY} ${HONOURS_ORDER_KEY}`}/>
					</OrderByIconContainer>
					<OrderByIconContainer isSelected={this.props.orderBy.category === OrderCategoriesEnum.numberTitles} onClick={this._onOrderByNumberOfTitles}>
						<i className='fa fa-trophy' title={`${SORT_TITLE_KEY} ${NUMBER_HONOURS_ORDER_KEY}`}/>
					</OrderByIconContainer>
					<OrderByIconContainer isSelected={this.props.orderBy.category === OrderCategoriesEnum.foundation} onClick={this._onOrderByFoundation}>
						<i className='fa fa-calendar' title={`${SORT_TITLE_KEY} ${YEAR_ORDER_KEY}`}/>
					</OrderByIconContainer>
					<OrderByIconContainer isSelected={this.props.orderBy.category === OrderCategoriesEnum.category} onClick={this._onOrderByCategory}>
						<i className='fa fa-list' title={`${SORT_TITLE_KEY} ${CATEGORY_ORDER_KEY}`}/>
					</OrderByIconContainer>
				</FilterContainerTopRow>
				<FilterContainerRow>
					{this._renderRegionsDropdown()}
					{this._renderMapAndSimplifiedViewIcons()}
				</FilterContainerRow>
			</OrderContainer>
		);
	}

	private _renderTitle = () => {
		if (this.props.code && this.props.name) {
			if (this.props.code === ALL_COUNTRIES_NAME) {
				return (
					<title>{'Scarves from all clubs in collection - FootballScarfMuseumCollection'}</title>
				);
			} else {
				return (
					<title>{`Scarves from football clubs from ${this.props.name} - FootballScarfMuseumCollection`}</title>
				);
			}
		}
		return (
			<title>{'Scarves - FootballScarfMuseumCollection'}</title>
		);
	}

	private _renderHighlightsPopUp = () => {
		return (
			<HighlightsPopUp/>
		);
	}

	private _onTeamGalleryScroll = () => {
		if (this.props.trophyTooltip) {
			this.props.dispatch(actions.setTrophyTooltip(null));
		}
	}

	private _closeTrophyTooltipCallback = () => {
		this.props.dispatch(actions.setTrophyTooltip(null));
	}

	private _renderTrophyTooltip = () => {
		if (!this.props.trophyTooltip) {
			return null;
		}
		return (
			<TrophyTooltip
				trophyTooltip={this.props.trophyTooltip}
				closeCallback={this._closeTrophyTooltipCallback}
			/>
		);
	}

	private _closeDetailTrophyTooltipCallback = () => {
		this.props.dispatch(actions.hideDetailTrophyTooltip());
	}

	private _renderDetailTrophyTooltip = () => {
		if (!this.props.detailTrophyTrophy) {
			return null;
		}
		return (
			<DetailTrophyTooltip
				detailTrophyTooltip={this.props.detailTrophyTrophy}
				closeCallback={this._closeDetailTrophyTooltipCallback}
				isMobileViewActive={this.props.isMobileViewActive}
			/>
		);
	}

	private _onSelectByCountryCode = (countryCode: string) => {
		this.props.dispatch(actions.selectByCountryCode(countryCode));
	}

	private _onConfirmSelect = () => {
		this.setState({
			renderSelectByCountry: false
		});
		this.props.dispatch(actions.confirmSelectByCountry());
	}

	public render() {
		if (this.state.renderSelectByCountry) {
			const orderedCountryNameCodes: { name: string; countryCode: string; }[] = this.props.countryCodes
				.map(c => {
					const name = Localizer.getLocalizedCountryName(c);
					return {
						name,
						countryCode: c 
					};
				})
				.sort((a, b) => a.name > b.name ? 1 : -1);
			return (
				<SelectCountryFlexContainer ref={this._bckContainerGetRef}>
					<StyledFilterByCountryButton
						onClick={this._onConfirmSelect}
					>
						<Link to={`/${SELECTED_COUNTRIES}`}>
							{'Select countries and click'}
						</Link>
					</StyledFilterByCountryButton>

					<CountryListContainer>
						{orderedCountryNameCodes.map(orderedCountryNameAndCode => {
							return (
								<StyledCountryRow
									isSelected={this.props.orderBy.countryCodes[orderedCountryNameAndCode.countryCode] ? true : false}
									onClick={() => this._onSelectByCountryCode(orderedCountryNameAndCode.countryCode)}
								>
									<span>
										<ImageLinkContainer src={`../../../img/flag/${orderedCountryNameAndCode.countryCode}.png`} alt={orderedCountryNameAndCode.name} />
									</span>
									<span>{orderedCountryNameAndCode.name}</span>
								</StyledCountryRow>
							);
						})}
					</CountryListContainer>
				</SelectCountryFlexContainer>
			);
		} else {
			return (
				<React.Fragment>
					<Helmet>
						{this._renderTitle()}
					</Helmet>
					<GalleryContainer ref={this._bckContainerGetRef}>
						{this._renderOrderContainer()}
						{this._renderHighlightsPopUp()}
						{this._renderTrophyTooltip()}
						{this._renderDetailTrophyTooltip()}
						<ContentContainer ref={this._containerGetRef} onScroll={this._onTeamGalleryScroll}>
							{this.props.currentGroupScarves.map(x => this._renderScarvesOfTeam(x))}
						</ContentContainer>
					</GalleryContainer>
				</React.Fragment>
			);
		}
	}
}
export default connect(rootSelector)(Gallery);

