import { INumberDictionary } from '../interfaces';
import { IAvailableCompetition, ICompetitionEvolution, IDetailTrophy, IEditionEvolution, IHonours, ITitleEdition } from '../interfaces/clientInterfaces';
import { IDBCompetition, IDBEdition } from '../interfaces/dbInterfaces';

export class Editions {

	private static _getDetailTrophyOfEdition = (
		edition: ITitleEdition,
		competitionsTrophies: number[]
	): IDetailTrophy => {
		for (const trophyYear of competitionsTrophies) {
			if (edition.yearEnd <= trophyYear) {
				return {
					text: edition.text,
					trophy: trophyYear.toString(),
					yearEnd: edition.yearEnd,
					yearEndText: edition.yearEndText
				};
			}
		}
		return {
			text: edition.text,
			trophy: '',
			yearEnd: edition.yearEnd,
			yearEndText: edition.yearEndText
		};
	}

	private static _setChampionEditionOnTeam = (
		honours: IHonours,
		edition: IDBEdition,
		parsedCompetitionId: number,
		competitionsTrophies: Map<number, number[]>,
		competitionDescription: string | undefined
	) => {
		const competitionIndex = honours.completeChampion.findIndex(x => x.competitionId === parsedCompetitionId);
		const editionName = `${edition.yearStart}${edition.yearStart !== edition.yearEnd ? `-${edition.yearEnd}` : ''} ${edition.alternativeShortName || ''}`;
		const titleEdition: ITitleEdition = {
			text: editionName,
			yearEnd: edition.yearEnd,
			yearEndText: `${edition.yearEnd}${edition.alternativeShortName ? ` ${edition.alternativeShortName}` : ''}`
		};
		const trophies = competitionsTrophies.get(parsedCompetitionId);
		if (trophies) {
			const detailTrophy = Editions._getDetailTrophyOfEdition(titleEdition, trophies);
			if (competitionIndex > -1) {
				honours.completeChampion[competitionIndex] = {
					...honours.completeChampion[competitionIndex],
					editions: honours.completeChampion[competitionIndex].editions.concat([titleEdition]),
					detailedTrophies: honours.completeChampion[competitionIndex].detailedTrophies.concat([detailTrophy]),
					lastTrophy: detailTrophy.trophy,
					description: competitionDescription
				};
			} else {
				honours.completeChampion.push({
					competitionId: parsedCompetitionId,
					editions: [titleEdition],
					lastTrophy: detailTrophy.trophy,
					detailedTrophies: [detailTrophy],
					description: competitionDescription
				});
			}
		}

		return honours;
	}

	private static _setRunnerUpEditionOnTeam = (
		honours: IHonours,
		edition: IDBEdition,
		parsedCompetitionId: number,
		competitionsTrophies: Map<number, number[]>,
		competitionDescription: string | undefined
	) => {
		const competitionIndex = honours.completeRunnerUp.findIndex(x => x.competitionId === parsedCompetitionId);
		const editionName = `${edition.yearStart}${edition.yearStart !== edition.yearEnd ? `-${edition.yearEnd}` : ''} ${edition.alternativeShortName || ''}`;
		const titleEdition: ITitleEdition = {
			text: editionName,
			yearEnd: edition.yearEnd,
			yearEndText: `${edition.yearEnd}${edition.alternativeShortName ? ` ${edition.alternativeShortName}` : ''}`
		};
		const trophies = competitionsTrophies.get(parsedCompetitionId);
		if (trophies) {
			const detailTrophy = Editions._getDetailTrophyOfEdition(titleEdition, trophies);
			if (competitionIndex > -1) {
				honours.completeRunnerUp[competitionIndex] = {
					...honours.completeRunnerUp[competitionIndex],
					editions: honours.completeRunnerUp[competitionIndex].editions.concat([titleEdition]),
					detailedTrophies: honours.completeRunnerUp[competitionIndex].detailedTrophies.concat([detailTrophy]),
					lastTrophy: detailTrophy.trophy,
					description: competitionDescription
				};
			} else {
				honours.completeRunnerUp.push({
					competitionId: parsedCompetitionId,
					editions: [titleEdition],
					lastTrophy: detailTrophy.trophy,
					detailedTrophies: [detailTrophy],
					description: competitionDescription
				});
			}
		}
		return honours;
	}

	private static _getEditionEvolution = (
		edition: IDBEdition,
		existingCompetitionEvolutions: INumberDictionary<ICompetitionEvolution>,
		competitionsWithEvolution: INumberDictionary<IAvailableCompetition>
	): INumberDictionary<ICompetitionEvolution> => {
		const competitionEvolutions = { ...existingCompetitionEvolutions };

		if (competitionsWithEvolution[edition.competitionId]) {
			if (typeof competitionEvolutions[edition.competitionId] === 'undefined') {
				competitionEvolutions[edition.competitionId] = {
					startYear: 0,
					endYear: 0,
					competitionId: edition.competitionId,
					description: 'This is a dummy description',
					editions: [],
					totalSpans: 0
				};
			}
			const competitionEvolution = competitionEvolutions[edition.competitionId];
			if (!competitionEvolution) {
				// Fallback
				return competitionEvolutions;
			}
			const yearText = `${edition.yearEnd} ${edition.alternativeShortName ?? ''}`;
			const editionEvolution: IEditionEvolution = {
				startYear: edition.yearStart,
				endYear: edition.yearEnd,
				yearText,
				lastChampionId: edition.championTeamId,
				lastRunnerUpId: edition.runnerUpTeamId,
				annotation: edition.alternativeShortName,
				description: `${edition.yearEnd !== edition.yearStart ? `${edition.yearStart}/${edition.yearEnd}` : edition.yearEnd}${edition.alternativeShortName ? ` ${edition.alternativeShortName}` : ''}`,
				champions: {},
				runnerUps: {},
				span: 1
			};
			if (competitionEvolution.editions.length === 0) {
				competitionEvolution.startYear = edition.yearEnd;
				editionEvolution.champions = {
					[edition.championTeamId]: [edition.yearEnd]
				};
				editionEvolution.runnerUps = {
					[edition.runnerUpTeamId]: [edition.yearEnd]
				};
			} else {
				const previousEdition = competitionEvolution.editions[competitionEvolution.editions.length - 1];
				// Did team already win?
				const championYears = previousEdition.champions[edition.championTeamId];
				if (typeof championYears === 'undefined') {
					editionEvolution.champions = {
						...previousEdition.champions,
						[edition.championTeamId]: [edition.yearEnd]
					};
				} else {
					editionEvolution.champions = {
						...previousEdition.champions,
						[edition.championTeamId]: [...championYears, edition.yearEnd]
					};
				}
				// Did team already run up?
				const runnerUpYears = previousEdition.runnerUps[edition.runnerUpTeamId];
				if (typeof runnerUpYears === 'undefined') {
					editionEvolution.runnerUps = {
						...previousEdition.runnerUps,
						[edition.runnerUpTeamId]: [edition.yearEnd]
					};
				} else {
					editionEvolution.runnerUps = {
						...previousEdition.runnerUps,
						[edition.runnerUpTeamId]: [...runnerUpYears, edition.yearEnd]
					};
				}
				competitionEvolution.endYear = edition.yearEnd;
			}
			competitionEvolution.totalSpans++;
			competitionEvolution.editions.push(editionEvolution);
		}
		return competitionEvolutions;
	}

	public static getInformationFromEditions = (
		editions: IDBEdition[],
		competitionsWithEvolution: INumberDictionary<IAvailableCompetition>,
		competitionsTrophies: Map<number, number[]>,
		competitionsDictionary: INumberDictionary<IDBCompetition>
	): {
		honoursByTeamMap: Map<number, IHonours>;
		competitionEvolutions: INumberDictionary<ICompetitionEvolution>;
	} => {
		const honoursByTeamMap = new Map<number, IHonours>();
		let competitionEvolutions: INumberDictionary<ICompetitionEvolution> = {};

		for (const edition of editions) {
			const parsedCompetitionId = edition.competitionId;
			if (!Number.isNaN(parsedCompetitionId)) {
				const honoursOfChampion = honoursByTeamMap.get(edition.championTeamId);
				const competition = competitionsDictionary[parsedCompetitionId];
				if (honoursOfChampion) {
					honoursByTeamMap.set(edition.championTeamId, Editions._setChampionEditionOnTeam(honoursOfChampion, edition, parsedCompetitionId, competitionsTrophies, competition?.description));
				} else {
					honoursByTeamMap.set(edition.championTeamId, Editions._setChampionEditionOnTeam({
						completeChampion: [],
						champion: [],
						runnerUp: [],
						completeRunnerUp: [],
						numberOfTitles: 0
					}, edition, parsedCompetitionId, competitionsTrophies, competition?.description));
				}
				const honoursOfRunnerUp = honoursByTeamMap.get(edition.runnerUpTeamId);
				if (honoursOfRunnerUp) {
					honoursByTeamMap.set(edition.runnerUpTeamId, Editions._setRunnerUpEditionOnTeam(honoursOfRunnerUp, edition, parsedCompetitionId, competitionsTrophies, competition?.description));
				} else {
					honoursByTeamMap.set(edition.runnerUpTeamId, Editions._setRunnerUpEditionOnTeam({
						completeChampion: [],
						champion: [],
						runnerUp: [],
						numberOfTitles: 0,
						completeRunnerUp: [],
					}, edition, parsedCompetitionId, competitionsTrophies, competition?.description));
				}
			}
			competitionEvolutions = Editions._getEditionEvolution(edition, competitionEvolutions, competitionsWithEvolution);
		}
		return {
			honoursByTeamMap,
			competitionEvolutions
		};
	}
}
