import { INumberDictionary, IStringDictionary } from '.';
import { IDBCompetition } from './dbInterfaces';

export interface ITeam {
	teamId: number;
	teamCode: string;
	countryCode: string;
	name: string;
	founded: number | null;
	dissolved: number | null;
	category: number | null;
	categoryString: string;
	firstColor: string;
	secondColor: string;
	thirdColor: string;
	seasonsTop: number;
	seasonsTop2: number;
	countryCode2: string | undefined;
	// calculated
	honours: IHonours;
	stadium: IStadium;
	seasonsTopPercentage: number;
	noTitleRanking: number;
	overallRanking: number;
	city: ICity;
	seasonsTopTotal: number;
	seasonsTopTotal2: number;
	shields: string[];
}

export interface ICity {
	cityId: number;
	name: string;
	population: number;
	region: string;
	country: string;
}

export interface IStadium {
	stadiumId: number;
	cityId: number;
	name: string;
	north: number;
	east: number;
	capacity: number;
	opened?: number;
}

export interface IHonours {
	completeChampion: ITitle[];
	champion: ITitle[];
	runnerUp: ITitle[];
	completeRunnerUp: ITitle[];
	numberOfTitles: number;
}

export interface ITitle {
	competitionId: number;
	editions: ITitleEdition[];
	detailedTrophies: IDetailTrophy[];
	competitionCode?: string;
	name?: string;
	lastTrophy: string;
	description: string | undefined;
}

export interface IDetailTrophy {
	trophy: string;
	text: string;
	yearEnd: number;
	yearEndText: string;
}

export interface ITitleEdition {
	text: string;
	yearEnd: number;
	yearEndText: string;
}

export interface IScarf {
	scarfCode: string;
	teamId: number;
	matchId: number;
	exchangeable: boolean;
	date?: string;
}

export interface IMatch {
	matchId: number;
	localGoals: number;
	visitantGoals: number;
	isExtraTime: boolean;
	localPenaltyGoals: number;
	visitantPenaltyGoals: number;
	round: string;
	isRM: boolean;
	awayKitColor: string;
	homeKitColor: string;
}


export interface IHighlightPopUp {
	highlights: any[];
	name: string;
	teamId: number;
}

export interface ITrophyTooltip {
	x: number;
	y: number;
	competitionName: string;
	title: string,
	editions: string;
	description: string | undefined;
	hasDetail: boolean;
}

export interface IPagingInfo {
	currentPage: number;
	totalPages: number;
	teamsPerPage: number;
}

export interface IOrderBy {
	category: OrderCategoriesEnum;
	ascending: boolean;
	confederations: ConfederationsEnum[];
	countryCodes: IStringDictionary<boolean>;
	matchCategory: MatchOrderCategoriesEnum[];
	matchCompetition: OrderMatchCompetition[];
	matchAscending: boolean;
	orderByRegion: string | null;
}

export interface IImage {
	name: string;
	base64Image: string;
}

export enum ConfederationsEnum {
	eu = 'EU',
	sa = 'SA',
	na = 'NA',
	af = 'AF',
	as = 'AS',
	oc = 'OC'
}


export interface IScarfInfo {
	team: ITeam;
	stadium: IStadium;
	city: ICity;
	scarves: IScarf[];
}

export interface IMatchScarvesGroup {
	scarfCodes: string[];
	matches: IMatch[];
	yearEnd: number;
	firstTeam?: ITeam;
	secondTeam?: ITeam;
	competition?: IDBCompetition;
	stadium?: IStadium;
	thirdTeam?: ITeam;
	fourthTeam?: ITeam;
	isRM?: boolean;
	round?: string;
}

export interface IBasicInfo {
	scarves: IScarf[];
	teams: ITeam[];
	stadiums: IStadium[];
	cities: ICity[];
	competitions: IDBCompetition[];
}

export interface IScarfUpdate {
	code: string;
	date: Date;
	name: string;
	countryOrCompetitionCode: string;
	isMatch: boolean;
	teamId: number;
}

export enum OrderCategoriesEnum {
	alphabetically = 'alphabetically',
	category = 'category',
	numberTitles = 'numberTitles',
	honours = 'honours',
	stadium = 'stadium',
	population = 'population',
	foundation = 'foundation',
	seasonsTop = 'seasonTop'
}

export enum MatchOrderCategoriesEnum {
	competition = 'competition',
	round = 'round',
	chronologically = 'chronologically'
}

export enum OrderMatchCompetition {
	ucl = 'EU_UCL',
	uel = 'EU_UEL',
	ucw = 'EU_UCW',
	uco = 'EU_COL',
	usc = 'EU_USC',
	cwc = 'FF_CWC',
	fic = 'FF_IC',
	fwc = 'FF_WC',
	uec = 'EU_EC',
	cit = 'IT_C',
	ces = 'ES_C',
	lib = 'SA_LIB',
	sud = 'SA_SUD',
	cat = 'AT_C'
}

export enum EnumSection {
	home = 'home',
	map = 'map',
	matches = 'matches',
	gallery = 'gallery',
	rm = 'rm',
	competitions = 'competitions'
}

export interface ILinkProp {
	name: string;
	link?: string;
	team?: string;
	country?: string;
	description?: string;
	mail?: string;
	ranking: number;
}

export interface ICenterOfCountry {
	center: number[];
	zoom: number;
	mobileZoom: number
}

export interface ICompetitionEvolution {
	startYear: number;
	endYear: number;
	competitionId: number;
	description: string;
	editions: IEditionEvolution[];
	totalSpans: number;
}

export interface IEditionEvolution {
	startYear: number;
	endYear: number;
	yearText: string;
	description: string;
	lastChampionId: number;
	lastRunnerUpId: number;
	annotation: string;
	champions: INumberDictionary<number[]>; // Number of champions editions by team id
	runnerUps: INumberDictionary<number[]>; // Number of runner up edition by team id
	span: number;
}

export interface IAvailableCompetition {
	id: number;
	name: string;
	competitionCode: string;
	regionCode: string;
	isContinental: boolean;
	totalEditions?: number;
}

export interface ISelectedEvolutions {
	competitionName: string;
	competitionId: number;
	competitionTrophy: string;
	competitionDescription: string;
	evolution: ICompetitionEvolution;
	selectedEndYear: number;
	selectedStartIndex: number;
	selectedStartYear: number;
	selectedStartYearText: string;
	selectedEndYearText: string;
	selectedEndIndex: number;
	selectedTimeStatus: ITeamTimeHonours[];
	maxTitlesInCurrentStatus: number;
	lastChampionCode: string;
	lastChampionId: number;
	lastChampionRegionCode: string;
	lastRunnerUpRegionCode: string;
	lastRunnerUpCode: string;
	lastRunnerUpId: number;
	lastChampionTooltip: string;
	lastRunnerUpTooltip: string;
}

export interface ITeamTimeHonours {
	teamName: string;
	teamId: number;
	timesChampion: number;
	timesRunnerUp: number;
	shieldCode: string;
	scarfCode: string;
	regionCode: string;
}

export interface IDetailTrophyTooltip {
	title: string;
	detailTrophiesByCompetition: IDetailTrophyForTooltip[];
	detailTrophiesByYearEnd: IDetailTrophyForTooltip[];
	completeDetailTrophiesByCompetition: IDetailTrophyForTooltip[];
	completeDetailTrophiesByYearEnd: IDetailTrophyForTooltip[];
	orderBy: OrderByDetailTrophyTooltip;
	showCompleteRunnerUps: boolean;
}

export enum OrderByDetailTrophyTooltip {
	chronologically = 'chronologically',
	competition = 'competition'
}

export interface IDetailTrophyForTooltip {
	text: string;
	trophy: string;
	yearEnd: number;
	yearEndText: string;
	isRunnerUp: boolean;
}
