import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { connect } from 'react-redux';

import rootSelector from '../selectors/map';
import { IMapCombinedProps, INumberDictionary } from '../../interfaces';

import * as actions from '../../actions';
import TeamInfoRenderer from './TeamInfoRenderer';

import { Helmet } from 'react-helmet';

import * as L from 'leaflet';
import queryString from 'query-string';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import TrophyTooltip from './TrophyTooltip';
import { ConfederationsEnum, EnumSection, ICenterOfCountry, IScarfInfo, ITeam } from '../../interfaces/clientInterfaces';
import { Localizer } from '../../utils/localizer';
import { Countries } from '../../utils/countries';
import DetailTrophyTooltip from './DetailTrophyTooltip';
const MapModuleContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
`;

const MapContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

const MapFlexContainer = styled.div`
	display: flex; 
	flex: 1;
`;

interface IDetailInfoContainer {
	isMobileViewActive: boolean;
}
const DetailTeamInfoContainer = styled.div<IDetailInfoContainer>`
	background: url('./img/background/bck_theme.jpg') no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	max-width: ${props => props.isMobileViewActive ? '100vw' : '40%'};
	overflow-y: auto;
`;

interface IFlexColumnContainer {
	maxWidth: string;
}
const FlexColumnContainer = styled.div<IFlexColumnContainer>`
	display: flex;
	flex-direction: column;
	max-width: ${props => props.maxWidth};
	transition: max-width 0.75s;

`;

const MarginWithPassing = styled.div`
	padding: 10px;
`;

const ClusterIcon = styled.span`
	font-family: Champions;
	font-size: 13px;
	text-shadow: 2px 2px 2px gold;
	color: black;
	img {
		height: 20px;
	}
	div {
		width: 120px;
		left: -30px;
		position: relative;
	}
`;


class ScarfMap extends PureComponent<IMapCombinedProps> {

	public componentDidMount() {
		this.props.dispatch(actions.setSectionName(EnumSection.map));
		if (this.props.teams.length > 0) {
			this._renderTeamsInMap();
		}
	}

	public componentDidUpdate(prevProps: IMapCombinedProps) {
		if (this.props.teams.length !== prevProps.teams.length) {
			this._renderTeamsInMap();
		}
	}

	private _renderClusterIcon = (title: string, icon: string, markersLength: number) => {
		return (
			<ClusterIcon>
				<img src={`../../../img/flag/${icon}.png`} alt={title} />
				<div>{`${title}: ${markersLength} teams`}</div>
			</ClusterIcon>
		);
	}

	private _renderTeamsInMap = () => {
		let centerAt = null;
		const parsedQuery = queryString.parse(window.location.search);
		for (const key of Object.keys(parsedQuery)) {
			if (key.toLowerCase() === 'center') {
				centerAt = parsedQuery[key];
			}
		}
		const teamToCenterAt = this.props.teamStadiumCoordinatesMap.get(centerAt);
		const center: ICenterOfCountry = !teamToCenterAt 
			? Countries.getCenterOfCountry(centerAt || '')
			: {
				center: teamToCenterAt,
				mobileZoom: 10,
				zoom: 10
			};

		const scarvesMap = L.map('mapId').setView(center.center, this.props.isMobileViewActive ? center.mobileZoom : center.zoom);
		L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
			attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
			maxZoom: 11,
			id: 'mapbox/streets-v11',
			tileSize: 512,
			zoomOffset: -1,
			accessToken: 'pk.eyJ1IjoiYWdhcmNhaCIsImEiOiJjazhieG50NnYwMDRvM2VwNmZjZHd1aDBzIn0.GKhS7ajz4tM51I_pplBlKA'
		}).addTo(scarvesMap);

		const clusterGroups: { title: string, markers: any[], icon: string }[] = [];
		const clusterGroupsIndices: Map<string, number> = new Map();

		const array: any[] = []; // TODO and add priority
		for (const team of this.props.teams) {
			array.push(this._setTeamMarker(team, scarvesMap, this.props.stadiumCluster));
			const localizedCountryName = Localizer.getLocalizedCountryName(team.countryCode);
			const clusterIndex = clusterGroupsIndices.get(localizedCountryName);
			if (clusterIndex !== undefined) {
				clusterGroups[clusterIndex].markers.push(
					this._setTeamMarker(team, scarvesMap, this.props.stadiumCluster)
				);
			} else {
				clusterGroups.push({
					title: localizedCountryName,
					icon: team.countryCode,
					markers: [this._setTeamMarker(team, scarvesMap, this.props.stadiumCluster)]
				});
				clusterGroupsIndices.set(localizedCountryName, clusterGroups.length -1);
			}
		}
		for (const cluster of clusterGroups) {
			const self = this;
			const confederationOfCountryCode = Countries.getConfederationOfCountry(cluster.icon);
			let disableClusteringAtZoom = this.props.isMobileViewActive ? 6 : 7;
			if (
				ConfederationsEnum.sa === confederationOfCountryCode ||
				ConfederationsEnum.na === confederationOfCountryCode ||
				ConfederationsEnum.as === confederationOfCountryCode) {
				disableClusteringAtZoom = this.props.isMobileViewActive ? 3 : 4;
			} else if (
				ConfederationsEnum.af === confederationOfCountryCode ||
				cluster.icon === 'RU' ||
				cluster.icon === 'KZ'
			) {
				disableClusteringAtZoom = this.props.isMobileViewActive ? 4 : 5;
			}
			var markers = L.markerClusterGroup({
				maxClusterRadius: 50000,
				disableClusteringAtZoom,
				spiderfyDistanceMultiplier: 1.25,
				iconCreateFunction: (x: any) => {
					return L.divIcon({ html: ReactDOMServer.renderToString(self._renderClusterIcon(cluster.title, cluster.icon, cluster.markers.length)) });
				}
			});
			for (const marker of cluster.markers) {
				if (marker) {
					markers.addLayer(marker);
				}
			}
			scarvesMap.addLayer(markers);
		}

	}
 

	private _setTeamMarker = (
		team: ITeam,
		scarvesMap: any, 
		stadiumCluster: INumberDictionary<number[]>
	) => {
		const selfContext = this;
		const iconUrl = `../../../img/shield/${team.countryCode}_${team.teamCode}.png`;
		if (stadiumCluster.hasOwnProperty(team.stadium.stadiumId)) {
			const iconWidth = 30;
			const iconHeight = 30;
			let iconAnchor = [iconWidth / 2, iconHeight];
			const lengthOfStadiumCluster = stadiumCluster[team.stadium.stadiumId] ? stadiumCluster[team.stadium.stadiumId]?.length : 0;
			if (lengthOfStadiumCluster && lengthOfStadiumCluster > 1) {
				const indexOfTeamInStadiumCluster = stadiumCluster[team.stadium.stadiumId] ? stadiumCluster[team.stadium.stadiumId]?.findIndex(x => x === team.teamId) : undefined;
				if (lengthOfStadiumCluster === 2) {
					if (indexOfTeamInStadiumCluster === 0) {
						iconAnchor = [iconWidth / 2, 0];
					} else {
						iconAnchor = [iconWidth / 2, iconHeight];
					}
				} else if (lengthOfStadiumCluster === 3) {
					if (indexOfTeamInStadiumCluster === 0) {
						iconAnchor = [0, iconHeight / 2];
					} else if (indexOfTeamInStadiumCluster === 1) {
						iconAnchor = [iconWidth, iconHeight / 2];
					} else {
						iconAnchor = [iconWidth / 2, 3 * iconHeight / 4];
					}
				} else if (lengthOfStadiumCluster === 4) {
					if (indexOfTeamInStadiumCluster === 0) {
						iconAnchor = [0, iconHeight / 2];
					} else if (indexOfTeamInStadiumCluster === 1) {
						iconAnchor = [iconWidth, iconHeight / 2];
					} else if (indexOfTeamInStadiumCluster === 2) {
						iconAnchor = [iconWidth, iconHeight];
					} else if (indexOfTeamInStadiumCluster === 3) {
						iconAnchor = [0, iconHeight];
					}
				} else {
					// No stadium shared by 5 teams
				}
			}
			const icon = L.icon({
				iconUrl,
				iconAnchor, // point of the icon which will correspond to marker's location
			});
			return L.marker(
				[team.stadium.north, team.stadium.east],
				{
					icon,
					id_test: team.teamId,
					riseOnHover: true
				})
				// .addTo(scarvesMap)	
				.on('click', () => {
					selfContext.props.dispatch(actions.setMapDetailTeam(team.teamId));
				});
		}
	}

	private _closeTeamRenderer = () => {
		this.props.dispatch(actions.setMapDetailTeam(-1));
	}

	private _onSetDetailTrophyTooltip = (team: ITeam) => {
		this.props.dispatch(actions.onSetTitleDetail(team));
	}

	private _renderTeamInforTransitionedMargin = (mapDetailScarfInfo: IScarfInfo) => {
		return (
			<MarginWithPassing>
				<TeamInfoRenderer
					scarfInfo={mapDetailScarfInfo}
					isMapInfo={true}
					closeTeamRenderer={this._closeTeamRenderer}
					isMobileViewActive={this.props.isMobileViewActive}
					section={this.props.section}
					onTrophyClickCallback={this._onTrophyClickCallback}
					onSetDetailTrophyTooltip={this._onSetDetailTrophyTooltip}
				/>
			</MarginWithPassing>
		);
	}

	private _onTrophyClickCallback = (x: number, y: number, title: string, editions: string, description: string | undefined, competitionId: number) => {
		this.props.dispatch(actions.setTrophyTooltip({
			x,
			y,
			title,
			editions,
			description,
			hasDetail: !!this.props.competitionsWithEvolution[competitionId],
			competitionName: this.props.competitionsWithEvolution[competitionId]?.name ?? ''
		}));
	}

	private _closeTrophyTooltipCallback = () => {
		this.props.dispatch(actions.setTrophyTooltip(null));
	}

	private _renderTrophyTooltip = () => {
		if (!this.props.trophyTooltip) {
			return null;
		}
		return (
			<TrophyTooltip
				trophyTooltip={this.props.trophyTooltip}
				closeCallback={this._closeTrophyTooltipCallback}
			/>
		);
	}

	private _closeDetailTrophyTooltipCallback = () => {
		this.props.dispatch(actions.hideDetailTrophyTooltip());
	}

	private _renderDetailTrophyTooltip = () => {
		if (!this.props.detailTrophyTrophy) {
			return null;
		}
		return (
			<DetailTrophyTooltip
				detailTrophyTooltip={this.props.detailTrophyTrophy}
				closeCallback={this._closeDetailTrophyTooltipCallback}
				isMobileViewActive={this.props.isMobileViewActive}
			/>
		);
	}

	private _renderTeamInfoInMargin = () => {
		return (
			<FlexColumnContainer maxWidth={this.props.mapDetailScarfInfo ? '100vw' : '0'}>
				{
					this.props.mapDetailScarfInfo
						? this._renderTeamInforTransitionedMargin(this.props.mapDetailScarfInfo as IScarfInfo)
						: null
				}
			</FlexColumnContainer>
		);
	}

	private _renderMetaTitle = () => {
		return (
			<title>{'Scarf map - FootballScarfMuseumCollection'}</title>
		);
	}

	public render() {
		return (
			<>
				<Helmet>
					{this._renderMetaTitle()}
				</Helmet>
				<MapModuleContainer>
					{this._renderTrophyTooltip()}
					{this._renderDetailTrophyTooltip()}
					<MapFlexContainer>
						<MapContainer id='mapId'/>
					</MapFlexContainer>
					<DetailTeamInfoContainer isMobileViewActive={this.props.isMobileViewActive}>
						{this._renderTeamInfoInMargin()}
					</DetailTeamInfoContainer>
				</MapModuleContainer>
			</>
			
		);
	}
}
export default connect(rootSelector)(ScarfMap);
