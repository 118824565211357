import { useEffect } from 'react';

/**
 * `useClickOutside` will call the `callback` on a click outside `container`.
 * `callback` should be wrapped inside a `useCallback` call.
 */
export function useClickOutside(
	container: React.RefObject<HTMLElement | null>,
	callback: () => void,
) {
	useEffect(() => {
		const checkClickOutside = (e: MouseEvent | TouchEvent) => {
			if (container.current?.contains(e.target as Node | null)) {
				return;
			}

			callback();
		};

		document.addEventListener('click', checkClickOutside, {
			capture: true,
		});
		document.addEventListener('touchstart', checkClickOutside, {
			capture: true,
		});

		return () => {
			document.removeEventListener('click', checkClickOutside, {
				capture: true,
			});
			document.removeEventListener('touchstart', checkClickOutside, {
				capture: true,
			});
		};
	}, [callback, container]);
}
