import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { ISideTreeCombinedProps, ISideTreeEntry } from '../../interfaces';
import rootSelector from '../selectors/sideTree';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ALL_COUNTRIES_NAME, COMPETITION_LINK, MATCH_LINK, MATCH_NAME, REAL_MADRID, REAL_MADRID_NAME, SELECT_BY_COUNTRIES } from '../constants';
import onClickOutside from 'react-onclickoutside';
import * as actions from '../../actions';
import { Localizer } from '../../utils/localizer';
import { Competitions } from '../../utils/competitions';

//#region styled Components

const SideTreeContainer = styled.div`
	min-width: 275px;
	padding: 15px;
	overflow-y: auto;
	border-right: 1px solid #F89504;
	position: relative;
	left: 0;
	user-selct: none;
`;

export const StyledAnimatedOnHover = styled.div`
	transition: color, background-color;
	transition-property: color, background-color;
	transition-duration: 0.4s;
	transition-timing-function: ease-out;
	border-radius: 4px;
`;

export const StyledAnimatedOnHoverSpan = styled.span`
	transition: color, background-color;
	transition-property: color, background-color;
	transition-duration: 0.4s;
	transition-timing-function: ease-out;
	border-radius: 4px;
`;

interface IStyledLink {
	marginBottom?: number; 
}
const StyledLink = styled(StyledAnimatedOnHover)<IStyledLink>`
	font-family: Europa-Big;
	font-size: 16px;
	text-align: center;
	margin: auto;
	cursor: pointer;
	text-align: left;
	padding: 1.5px;
	margin-bottom: ${props => props.marginBottom ? `${props.marginBottom}px`: 0}
	a {
		text-decoration: none;
		color: white;
	}
	:hover {
		background-color: #F89504;
		a {
			color: black;
		}
	}
`;

export const LoadingIconContainer = styled.div`
	margin-top: 50px;
`;

export const LoadingIcon = styled.div`
	border: 16px solid transparent;
	border-top: 16px solid #F89504;
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin: auto;
`;

export const LoadingIconDesc = styled.div`
	font-sze: 18px;
	margin-top: 25px;
	text-align: center;
	font-family: 'Europa-big';
`;

const FlagImage = styled.img`
	width: 17px;
	margin-right: 5px;
	position: relative;
`;


//#endregion
class SideTree extends PureComponent<ISideTreeCombinedProps, {}> {

	public handleClickOutside = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (this.props.isMobileViewActive && this.props.showTreeMap) {
			this.props.dispatch(actions.hideTreeMap());
		}
	};

	private _renderLink = (sideEntry: ISideTreeEntry) => {
		switch (sideEntry.countryCode) {
			case MATCH_NAME:
				return (
					<Link to={`/${MATCH_LINK}`}>
						{`${sideEntry.name} (${sideEntry.totalNumber})`}
					</Link>
				);
			case REAL_MADRID:
				return (
					<Link to={`/${REAL_MADRID}`}>
						{`${REAL_MADRID_NAME} (${sideEntry.totalNumber})`}
					</Link>
				);
			case SELECT_BY_COUNTRIES:
				return (
					<Link to={`/${SELECT_BY_COUNTRIES}`}>
						{`${sideEntry.name}`}
					</Link>
				);
			case COMPETITION_LINK:
				return (
					<Link to={`/${COMPETITION_LINK}`}>
						{`${sideEntry.name}`}
					</Link>
				);
			default:
				return (
					<Link to={`/${Localizer.getLocalizedCountryName(sideEntry.countryCode)}`}>
						{sideEntry.name !== ALL_COUNTRIES_NAME && <FlagImage
							src={Competitions.getCompetitionRegionImagePath(sideEntry.countryCode)}
						/>}
						{`${sideEntry.name} ${sideEntry.totalNumber > 0 ? `(${sideEntry.totalTeamsNumber}${sideEntry.countryCode === ALL_COUNTRIES_NAME ? ' CLUBS' : ''}/${sideEntry.totalNumber}${sideEntry.countryCode === ALL_COUNTRIES_NAME ? ' SCARVES' : ''})` : ''}`}
					</Link>
				);
		}
	}

	private _renderCountries = () => {
		if (this.props.entries.length <= 2) {
			return (
				<LoadingIconContainer>
					<LoadingIcon/>
					<LoadingIconDesc>{'LOADING SCARVES...'}</LoadingIconDesc>
				</LoadingIconContainer>
				
			);
		} else {
			return this.props.entries.map((x, i) => {
				return (
					<StyledLink key={i} marginBottom={i === 2 || i === 3 || i === 9 ? 15 : 0}>
						{this._renderLink(x)}
					</StyledLink>
				);
			});
		}
		
	}

	public render() {
		
		return (
			<SideTreeContainer>
				{this._renderCountries()}
			</SideTreeContainer>
		);
	}
	//#endregion
}

export default connect(rootSelector)(onClickOutside(SideTree));
