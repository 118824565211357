import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickOutside } from './hooks';
import { EnumSection, ITrophyTooltip } from '../../interfaces/clientInterfaces';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IStore } from '../../interfaces';

interface ITrophyTooltipProps {
	trophyTooltip: ITrophyTooltip;
	closeCallback(): void;
}

interface ITrophyTooltipContainer {
	x: number;
	y: number;
}
const TrophyTooltipContainer = styled.div<ITrophyTooltipContainer>`
	position: absolute;
	left: ${props => props.x}px;
	top: ${props => props.y}px;
	font-size: 12px;
	width: 275px;
	min-height: 60px;
	border-radius: 4px;
	border: 1px  solid #F89504;
	color: white;
	text-align: center;
	text-shadow: 2px 2px 2px gold;
	background-color: black;
	padding: 8px;
	opacity: 0.8;
	user-select: none;
	z-index: 1111114;
	p {
		margin: 5px 0 0 0;
	}
`;

interface IDescriptionContainer {
	isMobileViewActive: boolean;
}
const DescriptionContainer = styled.div<IDescriptionContainer>`
	min-height: 50px;
	max-height: ${props => props.isMobileViewActive ? '60px' : '100px'};
	overflow-y: auto;
	margin-bottom: 8px;
`;

const Title = styled.div`
	font-size: 14px;
	margin-bottom: 8px;
	flex: 1;
	display: flex;
`;
const TitleText = styled.div`
	display: flex;
	margin: auto;
`;

const InfoIconContainer = styled.div`
	display: flex;
	flex: 0;
`;

const InfoIcon = styled.i`
	margin: auto;
	position: relative; 
	font-size: 15px;
	color: #F89504;
	background-color: black;
	cursor: pointer;
	padding: 2px;
	text-shadow: none;
	:hover {
		color: #F89504;
	}
`;

const StyledLink = styled(Link)`
	color: white;
	margin: auto;
`;

//#endregion

export default function TrophyTooltip({
	trophyTooltip,
	closeCallback
}: ITrophyTooltipProps) {

	const containerRef = useRef<HTMLDivElement>(null);

	const [isInfoVisible, setIsInfoVisible] = useState(false);

	const section = useSelector(
		(completeState: IStore): EnumSection => {
			return completeState.ui.section;
		});

	const isMobileViewActive = useSelector(
		(completeState: IStore): boolean => {
			return completeState.ui.isMobileViewActive;
		});

	const onClickOutside = useCallback(() => {
		closeCallback();

	}, [closeCallback]);

	useClickOutside(containerRef, onClickOutside);


	const onInfoButtonClick = useCallback(() => {
		setIsInfoVisible(!isInfoVisible);
	}, [isInfoVisible]);

	const renderInfoButton = useCallback(() => {
		if (!trophyTooltip.description) {
			return null;
		}
		return (
			<InfoIconContainer title={'Information about competition'}>
				<InfoIcon
					className={'fa fa-solid fa-info'}
					onClick={onInfoButtonClick}
				/>
			</InfoIconContainer>
		);
	}, [trophyTooltip.description, onInfoButtonClick]);

	const renderDescription = useCallback(() => {
		if (!trophyTooltip.description || !isInfoVisible) {
			return null;
		}
		return (
			<DescriptionContainer
				isMobileViewActive={isMobileViewActive}
			>
				{trophyTooltip.description}
			</DescriptionContainer>
		);
	}, [trophyTooltip.description, isInfoVisible, isMobileViewActive]);

	return (
		<TrophyTooltipContainer
			x={trophyTooltip.x}
			y={trophyTooltip.y}
			ref={containerRef}

		>
			<Title>
				{
					trophyTooltip.hasDetail && section !== EnumSection.competitions
						? <StyledLink to={`/competitions?competition=${trophyTooltip.competitionName}`}><TitleText>{trophyTooltip.title}</TitleText></StyledLink>
						: <TitleText>{trophyTooltip.title}</TitleText>
				}
				
				{renderInfoButton()}
			</Title>
			{renderDescription()}
			{trophyTooltip.editions}
		</TrophyTooltipContainer>
	);

}
