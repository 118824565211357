import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { EnumSection, IAvailableCompetition, ISelectedEvolutions } from '../../interfaces/clientInterfaces';
import { INumberDictionary, IStore } from '../../interfaces';
import { IDBCompetition } from '../../interfaces/dbInterfaces';
import CompetitionEvolution from './CompetitionEvolution';
import DropDown from './DropDown';
import { IDropDownItem } from './DropDown/interfaces';
import * as actions from '../../actions';
import { Competitions as CompetitionsUtils } from '../../utils/competitions';
import Editions from './Editions';
import Countries from './Countries';
import Participations from './Participations';

//#region styled Components
const CompetitionContainer = styled.div`
	display: flex;
	flex-direction: column;
	user-select: none;
	width: 100%;
	height: 100%;
	width: 100%;
	margin: auto;
	user-select: none;
	font-family: Europa-regular;
	background: url('../img/background/competition.jpg') no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`;

const SelectorContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin: 5px auto;
`;

interface IICon {
	isSelected: boolean;
}
const Icon = styled.i<IICon>`
	position: relative; 
	font-size: 20px;
	color: ${props => props.isSelected ? '#000' : '#F89504'};
	background-color: ${props => props.isSelected ? '#F89504' : '#000'};
	transition: color, color;
	transition-property: color, color;
	transition-duration: 0.4s;
	transition-timing-function: ease-out;
	cursor: pointer;
	margin: 0 5px;
	padding: 2px;
	border-radius: 4px;
	:hover {
		color: #000;
		background-color: #F89504;
	}
`;

interface IShieldAndNameAndContainer {
	isMobileViewActive: boolean;
}
const ShieldAndNameAndContainer = styled.div<IShieldAndNameAndContainer>`
	flex: 0;
	flex-direction: column;
	color: #fff;
	border-radius: 10px;
	display: flex;
	margin: ${props => props.isMobileViewActive ? 5 : 15}px;
	background-color: rgba(255, 255, 255, 0.4);
	border-radius: 4px;
	padding: 10px;
	color: black;
`;

interface ICompetitionsHeader {
	isMobileViewActive: boolean;
}
const CompetitionsHeader = styled.div<ICompetitionsHeader>`
	display: flex;
	flex-direction: ${props => props.isMobileViewActive ? 'column' : 'row'};
	margin: 10px auto;
	flex: 0;
`;

const StyledDropDown = styled(DropDown)`
	width: 300px;
`;

interface INameContainer {
	isMobileViewActive: boolean;
}
const NameContainer = styled.div<INameContainer>`
	font-size: ${props => props.isMobileViewActive ? 18 : 24}px;
	font-family: 'Europa-big';
	display: flex;
	flex-direction: row;
`;

interface IDescriptionContainer {
	isMobileViewActive: boolean;
}
const DescriptionContainer = styled.div<IDescriptionContainer>`
	font-size: ${props => props.isMobileViewActive ? 15 : 18}px;
	max-height: ${props => props.isMobileViewActive ? '70px' : '100px'};
	overflow: auto;
`;

const CompetitionsItem = styled.div`
	height: 30px;
	display: flex;
	flex-direciton: row;
	cursor: pointer;
`;

const CompetitionsFlagContainer = styled.div`
	width: 40px;
	display: block;
	margin: auto;
`;

const CompetitionRegionFlag = styled.img`
	max-height: 17px;
	margin: auto;
	display: block;
`;

const CompetitionTitleRegionFlag = styled.div`
	max-height: 20px;
	margin: auto 5px auto 0;
	display: block;
	img {
		height: 20px;
		display: flex;
	}
`;

const CompetitonName = styled.div`
	line-height: 30px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

interface ICompetitionContentInjectedProps {
	competitionsWithEvolution: INumberDictionary<IAvailableCompetition>;
	isMobileViewActive: boolean;
	competitionsDictionary: INumberDictionary<IDBCompetition>;
	selectedEvolutions: ISelectedEvolutions[];
	availableCompetitions: IAvailableCompetition[]
}

enum CompetitionSectionEnum {
	evolution = 'evolution',
	participantsMap = 'participantsMap',
	editions = 'editions',
	countries = 'countries',
	participations = 'participations'
}
export default function CompetitionContent() {

	const dispatch = useDispatch();

	const {
		competitionsWithEvolution,
		availableCompetitions,
		isMobileViewActive,
		competitionsDictionary,
		selectedEvolutions,
	} = useSelector(
		(completeState: IStore): ICompetitionContentInjectedProps => {
			return {
				competitionsWithEvolution: completeState.competitions.competitionsWithEvolution,
				isMobileViewActive: completeState.ui.isMobileViewActive,
				competitionsDictionary: completeState.competitions.competitionsDictionary,
				selectedEvolutions: completeState.competitions.selectedEvolutions,
				availableCompetitions: completeState.competitions.availableCompetitions
			}
		});
	
	const [section, setSection] = useState(CompetitionSectionEnum.evolution);

	useEffect(() => {
		dispatch(actions.setSectionName(EnumSection.competitions));
		const params = new URLSearchParams(window.location.search);
		const competitionParameter = params.get('competition');
		const competition = availableCompetitions.find(comp => comp.name === competitionParameter);
		if (competition) {
			const competitionToSelect = competitionsWithEvolution[competition.id];
			if (competitionToSelect) {
				dispatch(actions.addCompetition(competitionToSelect.id));
			}
		}
		
	}, [dispatch, availableCompetitions, competitionsWithEvolution]);



	const competition = useMemo(
		(): IDBCompetition | null => {
			const selectedEvolution = selectedEvolutions.length > 0 ? selectedEvolutions[0] : null;
			if (!selectedEvolution) {
				return null;
			}
			const availableCompetition = competitionsWithEvolution[selectedEvolution.competitionId]
			if (!availableCompetition) {
				return null;
			}
			const competition = competitionsDictionary[availableCompetition?.id];
			if (!competition) {
				return null;
			}
			return competition;
		},
		[competitionsWithEvolution, competitionsDictionary, selectedEvolutions]
	);

	useEffect(() => {
		if (section === CompetitionSectionEnum.countries) {
			if (!competition?.isInternational && !competition?.isContinental) {
				setSection(CompetitionSectionEnum.evolution);
			}
		} else if (section === CompetitionSectionEnum.participations) {
			if (competition?.competition_code !== 'L') {
				setSection(CompetitionSectionEnum.evolution);
			}
		}
	}, [competition, section]);

	const { competitionDropDownOptions, mapOfRegionCodes } = useMemo(
		(): {
			competitionDropDownOptions: IDropDownItem[];
			mapOfRegionCodes: Map<string, string>;
		} => {
			const mapOfRegionCodes = new Map<string, string>();
			const competitionDropDownOptions = availableCompetitions.map(aC => {
				mapOfRegionCodes.set(aC.id.toString(), aC.regionCode);
				return {
					text : aC.name, 
					value: aC.id.toString()
				};
			});
			return { competitionDropDownOptions, mapOfRegionCodes };
		},
		[availableCompetitions]
	);

	const onAddCompetition = useCallback((value: string | null) => {
		if (value) {
			const competitionId = parseInt(value);
			if (!Number.isNaN(competitionId)) {
				dispatch(actions.addCompetition(competitionId));
			}
		}
	}, [dispatch]);

	const renderSection = () => {
		switch (section) {
			case CompetitionSectionEnum.evolution: {
				return <CompetitionEvolution/>;
			}
			case CompetitionSectionEnum.editions: {
				return <Editions/>;
			}
			case CompetitionSectionEnum.countries: {
				return <Countries/>;
			}
			case CompetitionSectionEnum.participations: {
				return <Participations />;
			}
			default:
				return null;
		}
	}

	const onEvolutionClick = () => {
		setSection(CompetitionSectionEnum.evolution);
	}

	const onEditionsClick = () => {
		setSection(CompetitionSectionEnum.editions);
	}

	const onCountriesClick = () => {
		setSection(CompetitionSectionEnum.countries);
	}

	const onParticipationsClick = () => {
		setSection(CompetitionSectionEnum.participations);
	}

	const renderDropDownValue = (item: IDropDownItem, mapOfRegionCodes: Map<string, string>) => {
		const regionCode = mapOfRegionCodes.get(item.value);
		if (!regionCode) {
			return (
				<CompetitionsItem>
					<CompetitonName>{item.text}</CompetitonName>
				</CompetitionsItem>
			);
		}
		return (
			<CompetitionsItem>
				<CompetitionsFlagContainer>
					<CompetitionRegionFlag
						src={CompetitionsUtils.getCompetitionRegionImagePath(regionCode)}
					/>
				</CompetitionsFlagContainer>
				<CompetitonName>{item.text}</CompetitonName>
			</CompetitionsItem>
		);
	}

	return (
		<CompetitionContainer>
			<CompetitionsHeader isMobileViewActive={isMobileViewActive}>
				<StyledDropDown
					value={''}
					options={competitionDropDownOptions}
					placeholder={'Select a competition from the list'}
					searchable={true}
					onChange={onAddCompetition}
					renderItem={(item: IDropDownItem, isSelected: boolean) => renderDropDownValue(item, mapOfRegionCodes)}
				/>
				{
					selectedEvolutions.length > 0 && <SelectorContainer>
						<Icon
							className={'fa fa-solid fa-chart-bar'}
							onClick={onEvolutionClick}
							isSelected={section === CompetitionSectionEnum.evolution}
							title={'Champions´ evolution'}
						/>
						<Icon
							className={'fa fa-solid fa-list'}
							onClick={onEditionsClick}
							isSelected={section === CompetitionSectionEnum.editions}
							title={'Editions'}
						/>
						{(competition?.isContinental || competition?.isInternational)
							? <Icon
								className={'fa fa-solid fa-flag'}
								onClick={onCountriesClick}
								isSelected={section === CompetitionSectionEnum.countries}
								title={'Winners by country'}
							/>
							: null}
						{competition?.totalEditions && competition?.totalEditions > 0
							? <Icon
								className={'fa fa-solid fa-list-ol'}
								onClick={onParticipationsClick}
								isSelected={section === CompetitionSectionEnum.participations}
								title={'Total seasons'}
							/>
							: null}
					</SelectorContainer>
				}
			</CompetitionsHeader>
			
			{
				competition && <ShieldAndNameAndContainer isMobileViewActive={isMobileViewActive}>
					<NameContainer isMobileViewActive={isMobileViewActive}>
						<CompetitionTitleRegionFlag>
							<img
								alt={competition.name}
								src={CompetitionsUtils.getCompetitionRegionImagePath(competition.region_code)}
							/>
						</CompetitionTitleRegionFlag>
						{competition.name}
					</NameContainer>
					<DescriptionContainer isMobileViewActive={isMobileViewActive}>
						{competition.description}
					</DescriptionContainer>
				</ShieldAndNameAndContainer>
			}
			
			{renderSection()}
		</CompetitionContainer>
	);
	
}
